import React from "react";

import { NavLink } from "react-router-dom";
import { Routes_path } from "../../routerApp";
import { useLocation } from "react-router-dom";

import ButtonGroup from "@mui/material/ButtonGroup";

import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import Box from "@mui/material/Box";
import VpnLockIcon from "@mui/icons-material/VpnLock";

const MenuItemStyle = {
	display: { sm: "inline-flex", xs: "flex" },
	padding: 0,
	ml: 1,
};

export enum Orientation {
	Horizontal = "horizontal",
	Vertical = "vertical",
}

interface HeaderMenuProps {
	// TODO gestire il tipo utente
	orientation: Orientation;
	activeStyle: React.CSSProperties;
}

const HeaderMenu = ({ orientation, activeStyle }: HeaderMenuProps) => {
	const location = useLocation();
	return (
		<>
			<Button
				aria-controls="simple-menu"
				component={NavLink}
				size="small"
				// activeStyle={activeStyle}
				// exact
				color="primary"
				to={Routes_path.HOME}
				sx={{
					ml: 1,
					color: location.pathname == Routes_path.HOME ? "#ff9100" : "#1b1b1b",
				}}
			>
				Homepage
				<IconButton
					size="large"
					color={location.pathname == Routes_path.HOME ? "primary" : "default"}
				>
					<Badge badgeContent={0}>
						<HomeIcon />
					</Badge>
				</IconButton>
			</Button>
			<Button
				aria-controls="simple-menu"
				component={NavLink}
				size="small"
				// activeStyle={activeStyle}
				// exact
				color="primary"
				to={Routes_path.CONTATTI}
				sx={{
					ml: 1,
					color:
						location.pathname == Routes_path.CONTATTI ? "#ff9100" : "#1b1b1b",
				}}
			>
				Contatti
				<IconButton
					size="large"
					color={
						location.pathname == Routes_path.CONTATTI ? "primary" : "default"
					}
				>
					<Badge badgeContent={0}>
						<AlternateEmailIcon />
					</Badge>
				</IconButton>
			</Button>

			{/* <Button
				aria-controls="simple-menu"
				component={NavLink}
				size="small"
				// activeStyle={activeStyle}
				// exact
				color="primary"
				to={Routes_path.PRIVACY}
				sx={{
					ml: 1,
					color:
						location.pathname == Routes_path.PRIVACY ? "#ff9100" : "#1b1b1b",
				}}
			>
				Privacy Policy
				<IconButton
					size="large"
					color={
						location.pathname == Routes_path.PRIVACY ? "primary" : "default"
					}
				>
					<Badge badgeContent={0}>
						<VpnLockIcon />
					</Badge>
				</IconButton>
			</Button> */}
		</>
	);
};

export default HeaderMenu;
