import React from "react";
import { Link } from "react-router-dom";

import { styled, experimental_sx as sx } from "@mui/material/styles";
import egg from "../../../static/images/common/egg.png";
import Typography from "@mui/material/Typography";
import MuiBox from "@mui/material/Box";
import Button from "@mui/material/Button";

const Box = styled(MuiBox)(({ theme }) =>
	sx({
		display: "flex",
		flexWrap: "wrap",
		"& > :not(style)": {
			// m: 1,
			marginTop: 5,
			maxWidth: "90%",
			height: "auto",
		},
		justifyContent: "center",
		border: 0,
		elevation: 0,
	})
);

const NotFoundPage = () => {
	return (
		<>
			<Box>
				<Typography variant="h4">404 - Page not found</Typography>
			</Box>
			<Box>
				<img src={egg} alt="404 - Pagina non trovata" />
			</Box>
			<Box>
				<Button
					sx={{
						backgroundColor: "#f8d300",
						"&:hover": {
							backgroundColor: "#a1887f",
						},
						color: "#222222",
						elevation: 2,
						align: "center",
					}}
					variant="contained"
					component={Link}
					to="/"
				>
					{"Back to Homepage"}
				</Button>
			</Box>
		</>
	);
};

export default NotFoundPage;
