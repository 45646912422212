import React, { useEffect, useRef } from "react";

// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components

// import { makeStyles } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MapIcon from "@mui/icons-material/Map";
import Grid from "@mui/material/Grid";
import Parallax from "../../common/Parallax/Parallax";

import ContattiImage from "../../../../static/images/common/contatti.jpg";
import { Stack } from "@mui/system";

export default function Contatti() {
	const topRef = useRef(null);
	// const executeScrollToTop = () => {
	// 	topRef.current.scrollIntoView();
	// };
	// useEffect(executeScrollToTop);

	// const classes = useStyles();
	// const { ...rest } = props;
	// const imageClasses = classNames(
	// 	classes.imgRaised,
	// 	classes.imgRoundedCircle,
	// 	classes.imgFluid
	// );
	const bull = <span>•</span>;
	// const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

	function createCookieData(
		nome: string,
		tipo: string,
		dettaglio: string,
		descrizione: string
	) {
		return { nome, tipo, dettaglio, descrizione };
	}
	const rowsCookie = [
		createCookieData(
			"Google Analytics",
			"Analytics di terze parti",
			".ga; .gat; _utmc; _utmv; _utmb; _utma; _utmz",
			"Utilizzati per la raccolta anonima di informazioni sugli utenti collegati"
		),
		createCookieData(
			"JavaScript",
			"Tecnico di sessione",
			".has_js",
			"Utilizzato per mantenere attiva l'informazione sulla autorizzazione all'uso di codice JavaScript"
		),
	];

	const containerFluid = {
		paddingRight: "15px",
		paddingLeft: "15px",
		marginRight: "auto",
		marginLeft: "auto",
		width: "100%",
	};
	const container = {
		...containerFluid,
		"@media (min-width: 576px)": {
			maxWidth: "540px",
		},
		"@media (min-width: 768px)": {
			maxWidth: "720px",
		},
		"@media (min-width: 992px)": {
			maxWidth: "960px",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "1140px",
		},
	};

	const parallaxContainer = {
		zIndex: "12",
		color: "#FFFFFF",
		...container,
	};

	return (
		<Box ref={topRef}>
			<Parallax small filter image={ContattiImage}>
				<Box sx={parallaxContainer}>
					<Grid container>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant="h1"
								color="#fff"
								sx={{ mt: 4, minHeight: "32px" }}
							>
								Contatti
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Parallax>
			<Box
				sx={{
					padding: "0px 16px",
					py: 10,
					px: 20,
					background: "#FFFFFF",
					position: "relative",
					zIndex: "3",
					margin: "-60px 30px 0px",
					borderRadius: "6px",
					boxShadow:
						"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
				}}
			>
				<Grid container>
					<Grid item xs={11} sm={11} md={5}>
						<Typography variant="h5">
							Per conoscere i nostri servizi contattaci:
						</Typography>
						<List>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<AlternateEmailIcon fontSize="large" />
								</ListItemAvatar>
								<ListItemText>
									<Typography variant="caption">
										mail:{" "}
										<a href="mailto:amministrazione@macelloscarso.it">
											amministrazione@macelloscarso.it
										</a>
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<LocalPhoneIcon fontSize="large" />
								</ListItemAvatar>
								<ListItemText>
									<Typography variant="caption">
										telefono: <a href="tel:+390498961477">+39 049 896 1477</a>
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<MapIcon fontSize="large" />
								</ListItemAvatar>
								<ListItemText>
									<Typography variant="caption">
										indirizzo: Via Garibaldi, 43 Ponte San Nicolo' (Padova)
									</Typography>
								</ListItemText>
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2803.2081714600504!2d11.915576015931942!3d45.36479414809137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477edb52a327ca7b%3A0xdaa9411fa6a04a46!2sScarso%20Romualdo%20%26%20Figli%20S.p.A.!5e0!3m2!1sit!2sit!4v1669058060751!5m2!1sit!2sit"
							width="600"
							height="450"
							// style="border:0;"
							// allowfullscreen=""
							loading="lazy"
							// referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
