import React from "react";
import { FC, useRef } from "react";
import RouterApp from "./routerApp";
// import SideBar from "./common/sideBar/SideBar";
// import TopBar from "./common/topBar/TopBar";

import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import { theme } from "../theme";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Routes_path } from "./routerApp";
import Footer from "./main/Footer/Footer";

import {
	ThemeProvider,
	styled,
	Theme,
	CSSObject,
	createTheme,
} from "@mui/material/styles";
// import { theme } from "../theme";
import TopBar from "./main/Header/TopBar";

import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Copyright from "./common/Copyright";
import { Stack } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
});

const HeaderSpacer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	marginTop: 45,
	// // necessary for content to be below app bar
	// ...theme.mixins.toolbar,
}));

const App = () => {
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	const ref = useRef<HTMLDivElement>(null);
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ display: "flex" }} ref={ref}>
				<CssBaseline />
				<Box component="main" sx={{ flexGrow: 1, p: 0 }}>
					<TopBar
						open={open}
						drawerWidth={drawerWidth}
						// handleDrawerOpen={handleDrawerOpen}
					/>
					{/* <HeaderSpacer /> */}
					<ToastContainer
						autoClose={3000}
						hideProgressBar
						pauseOnHover={true}
					/>
					<Box sx={{ minHeight: "90vh", p: 0, m: 0 }}>
						<RouterApp />
						<CookieConsent
							location="bottom"
							cookieName="technicalCookie"
							expires={30} //Number of days before the cookie expires.
							// overlay
							// debug={true}
							buttonStyle={{ background: "#ffd42d" }}
							buttonText="Ok, ho capito."
						>
							Il sito di Macello Scarso NON utilizza alcun cookie di
							profilazione, ma si avvale di cookie di tipo tecnico e di tipo
							analitico per informazioni aggregate ed anonime con finalità
							statistiche. Se vuoi saperne di più leggi&nbsp;
							<Link to={Routes_path.COOKIES}>
								<span style={{ color: "#ffd42d" }}>
									la nostra informativa sull’uso dei cookie
								</span>
							</Link>
						</CookieConsent>
					</Box>
				</Box>
			</Box>
			<Footer />
			<Copyright />
		</ThemeProvider>
	);
};

export default App;
