import React from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";

import HomePage from "./main/home/HomePage";
import Contatti from "./main/Contatti/Contatti";
import CookiePolicy from "./main/Policy/CookiePolicy";
import PrivacyPolicy from "./main/Policy/PrivacyPolicy";
import UnderConstruction from "./common/UnderConstruction";
import NotFoundPage from "./common/NotFoundPage";
import NotPermittedPage from "./common/NotPermittedPage";

export enum Routes_path {
	HOME = "/",
	CONTATTI = "/contatti",
	COOKIES = "/cookie_policy",
	PRIVACY = "/privacy_policy",
}

const RouterApp = () => {
	return (
		<ScrollToTop>
			<Routes>
				<Route path={Routes_path.HOME} element={<HomePage />} />
				<Route path={Routes_path.CONTATTI} element={<Contatti />} />
				<Route path={Routes_path.COOKIES} element={<CookiePolicy />} />
				<Route path={Routes_path.PRIVACY} element={<PrivacyPolicy />} />

				<Route path="/notPermitted" element={<NotPermittedPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</ScrollToTop>
	);
};

export default RouterApp;
