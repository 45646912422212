import React from "react";

import HeaderMenu, { Orientation } from "./HeaderMenu";

import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

import MoreIcon from "@mui/icons-material/MoreVert";
import MailIcon from "@mui/icons-material/Mail";

const Header = () => {
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const handleMobileMenuOpen = (event: any) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const activeStyle: React.CSSProperties = { color: "orange" };

	const mobileMenuId = "primary-search-account-menu-mobile";

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
			sx={[
				{
					"& .MuiPaper-root": {
						color: "red",
						backgroundColor: "#377199",
					},
				},
			]}
		>
			<HeaderMenu
				orientation={Orientation.Vertical}
				activeStyle={activeStyle}
			/>
		</Menu>
	);

	return (
		<>
			{/* visibile da sm in su */}
			<Box sx={{ display: { sm: "flex", xs: "none" } }}>
				<HeaderMenu
					orientation={Orientation.Horizontal}
					activeStyle={activeStyle}
				/>
			</Box>
			{/* visibile da xs in giù */}
			<Box sx={{ display: { sm: "none", xs: "flex" } }}>
				<IconButton
					aria-label="show more"
					aria-haspopup="true"
					onClick={handleMobileMenuOpen}
					color="inherit"
					size="large"
					sx={{ pr: 3 }}
				>
					<MoreIcon />
				</IconButton>
			</Box>
			{renderMobileMenu}
		</>
	);
};

export default Header;
