/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Routes_path } from "../../routerApp";

// @material-ui/icons
import Favorite from "@mui/icons-material/Favorite";
import logoBigliaBN from "../../../../static/images/logo/logo-Scarso_nuovo_solo.png";

import Grid from "@mui/material/Grid";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";

export default function Footer() {
	return (
		<Stack
			direction="row"
			spacing={2}
			justifyContent="space-between"
			sx={{ px: 10, mt: 2 }}
		>
			<Stack direction="row" spacing={2} justifyContent="flex-start">
				<Link to="/">
					<img style={{ width: 30 }} src={logoBigliaBN} />
				</Link>
				{/* <Link to="/chisiamo">Chi siamo</Link> */}
				{/* <Link to="/contatti">Contatti</Link> */}
				<Typography variant="button" component={Link} to={Routes_path.CONTATTI}>
					CONTATTI
				</Typography>
				<Typography variant="button" component={Link} to={Routes_path.PRIVACY}>
					Privacy Policy
				</Typography>
				<Typography variant="button" component={Link} to={Routes_path.COOKIES}>
					Cookie Policy
				</Typography>
			</Stack>
			<Box>&copy; {new Date().getFullYear()}, p.i.: 00227510286</Box>
		</Stack>
	);
}

// color={
//   location.pathname == Routes_path.PRIVACY ? "primary" : "default"
// }
