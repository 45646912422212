import React, { useEffect, useRef } from "react";

// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components

// import { makeStyles } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import Parallax from "../../common/Parallax/Parallax";

import PrivacyPolicyImage from "../../../../static/images/common/privacy_policy.jpg";

export default function PrivacyPolicy() {
	const topRef = useRef(null);
	// const executeScrollToTop = () => {
	// 	topRef.current.scrollIntoView();
	// };
	// useEffect(executeScrollToTop);

	// const classes = useStyles();
	// const { ...rest } = props;
	// const imageClasses = classNames(
	// 	classes.imgRaised,
	// 	classes.imgRoundedCircle,
	// 	classes.imgFluid
	// );
	const bull = <span>•</span>;
	// const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

	function createCookieData(
		nome: string,
		tipo: string,
		dettaglio: string,
		descrizione: string
	) {
		return { nome, tipo, dettaglio, descrizione };
	}
	const rowsCookie = [
		createCookieData(
			"Google Analytics",
			"Analytics di terze parti",
			".ga; .gat; _utmc; _utmv; _utmb; _utma; _utmz",
			"Utilizzati per la raccolta anonima di informazioni sugli utenti collegati"
		),
		createCookieData(
			"JavaScript",
			"Tecnico di sessione",
			".has_js",
			"Utilizzato per mantenere attiva l'informazione sulla autorizzazione all'uso di codice JavaScript"
		),
	];

	const containerFluid = {
		paddingRight: "15px",
		paddingLeft: "15px",
		marginRight: "auto",
		marginLeft: "auto",
		width: "100%",
	};
	const container = {
		...containerFluid,
		"@media (min-width: 576px)": {
			maxWidth: "540px",
		},
		"@media (min-width: 768px)": {
			maxWidth: "720px",
		},
		"@media (min-width: 992px)": {
			maxWidth: "960px",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "1140px",
		},
	};

	const parallaxContainer = {
		zIndex: "12",
		color: "#FFFFFF",
		...container,
	};

	return (
		<Box ref={topRef}>
			<Parallax small filter image={PrivacyPolicyImage}>
				<Box sx={parallaxContainer}>
					<Grid container>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant="h1"
								color="#fff"
								sx={{ mt: 4, minHeight: "32px" }}
							>
								Privacy Policy
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Parallax>
			<Box
				sx={{
					padding: "0px 16px",
					py: 10,
					px: 20,
					background: "#FFFFFF",
					position: "relative",
					zIndex: "3",
					margin: "-60px 30px 0px",
					borderRadius: "6px",
					boxShadow:
						"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={8}>
						<Typography variant="body1" sx={{ mt: 4 }}>
							Ai sensi dell’art. 13 D.Lgs. n. 196/2003 e s.m.i. (“Codice
							Privacy”), riportiamo le informazioni relative alla raccolta e al
							trattamento dei dati personali attraverso il sito
							www.macelloscarso.it (il “Sito”). La presente Privacy Policy si
							applica quando navighi il Sito. Ti invitiamo inoltre a leggere le
							Condizioni Generali d’Uso del Sito, in quanto contengono
							indicazioni anche sulla privacy e sui sistemi di sicurezza
							adottati dal Sito.
						</Typography>
						<Typography variant="h5" sx={{ mt: 8 }}>
							<Box fontWeight="Bold">1. TITOLARE DEL TRATTAMENTO</Box>
						</Typography>
						<Typography variant="body1" sx={{ mt: 4 }}>
							Il trattamento dei Dati Personali degli utenti del sito sarà
							effettuato in Italia e condotto in conformità con la normativa
							Europea applicabile ed il Codice Privacy, che disciplina il
							trattamento dei dati personali effettuati da chiunque è residente
							o ha sede in Italia. Titolare del trattamento è Scarso Romualdo &
							Figli S.r.l. con sede fiscale in Via Garibaldi, 43 (Ponte San
							Nicolo'), codice fiscale e partita IVA 00227510286 e assume il
							ruolo di Responsabile del trattamento nominato da Scarso Romualdo
							& Figli S.r.l. per la gestione del Sito e dei relativi servizi
							(manutenzione, aggiornamento, servizi di assistenza agli utenti,
							attività di marketing).
							<br />
							Il Sito è stato configurato affinché l’uso dei tuoi dati personali
							sia ridotto al minimo necessario ed in modo da escludere il
							trattamento quando le finalità perseguite nei singoli casi possono
							essere realizzate mediante l’uso di dati anonimi o mediante altre
							modalità, che consentono di identificare l’interessato solo in
							caso di necessità o su richiesta delle autorità e delle forze di
							polizia.
						</Typography>
						<Typography variant="h5" sx={{ mt: 8 }}>
							<Box fontWeight="Bold">
								2. MODALITÀ E FINALITÀ DEL TRATTAMENTO
							</Box>
						</Typography>
						<Typography variant="body1" sx={{ mt: 4 }}>
							Scarso Romualdo & Figli S.r.l. in qualità di co-titolare del
							trattamento dei dati personali degli utenti del Sito per le
							attività di gestione decide le finalità e le modalità dei suddetti
							trattamenti dei dati e gli strumenti da utilizzare anche per
							quanto concerne il profilo della sicurezza. <br />
							Per esigenze esclusivamente organizzative e funzionali, abbiamo
							nominato alcuni responsabili esterni del trattamento dei dati
							personali degli utenti del Sito, per finalità strettamente
							connesse e correlate alla prestazione dei servizi sul Sito. Questi
							responsabili sono stati scelti perché hanno dimostrato di
							possedere capacità, esperienza ed affidabilità, e di fornire
							idonea garanzia del pieno rispetto delle vigenti disposizioni in
							materia di trattamento, ivi compreso il profilo sulla sicurezza
							dei dati. I responsabili effettuano il trattamento dei dati
							personali degli utenti del Sito secondo le istruzioni impartite da
							Scarso Romualdo & Figli S.r.l. .
							<br />
							Per avere l’elenco aggiornato dei responsabili che trattano i tuoi
							dati, Ti invitiamo a contattare il Servizio Clienti oppure ad
							inviare una mail al seguente indirizzo di posta elettronica:
							amministrazione@macelloscarso.it.
							<br />I tuoi dati personali (a) sono trattati prevalentemente in
							formato elettronico e in talune ipotesi anche in formato cartaceo;
							(b) saranno conservati nella forma che permetta la tua
							identificazione per il tempo strettamente necessario alla finalità
							per cui sono stati raccolti e successivamente trattati e, in ogni
							caso, entro i limiti di legge; (c) fatto salvo per quanto
							diversamente previsto nella presente, non saranno comunicati a
							terzi per scopi non consentiti dalla legge o senza il tuo espresso
							consenso; (d) sono resi disponibili anche a soggetti terzi, per
							fini accessori e correlati all’erogazione dei servizi richiesti
							dall’utente; (e) potranno essere comunicati a forze di polizia o
							all’autorità giudiziaria, in conformità alla legge e qualora
							richiesto; (f) saranno inoltre accessibili ai Responsabili del
							trattamento, come indicato al presente paragrafo 2, e per le
							specifiche finalità ivi indicate; (g) potranno essere trasferiti
							all’estero, anche verso quei Paesi non appartenenti all’Unione
							Europea, che non assicurino livelli adeguati di tutela delle
							persone. Nel caso ciò fosse necessario per il raggiungimento delle
							finalità evidenziate, ti assicuriamo che il trasferimento dei tuoi
							dati personali verso i Paesi che non appartengono all’Unione
							Europea e che non assicurino livelli di tutela adeguati, saranno
							eseguiti solo previa conclusione tra Scarso Romualdo & Figli
							S.r.l. e detti soggetti di specifici contratti, contenenti
							adeguate clausole di salvaguardia per la protezione dei tuoi dati
							personali, in conformità alla legge ed ai regolamenti applicabili.
							Scarso Romualdo & Figli S.r.l. raccoglie i dati personali ed altre
							informazioni nell’ambito dei processi di registrazione online al
							Sito. Le finalità per le quali i tuoi dati personali sono note
							specificamente, di volta in volta, nel testo di informativa
							sottoposto nella pagina ove è richiesto il conferimento di dati
							personali. Nei casi in cui Scarso Romualdo & Figli S.r.l. tratti
							dati personali di terzi soggetti comunicati direttamente dai
							propri utenti sei invitato ad informare il terzo della presente
							Privacy Policy e ottenere il suo consenso, in quanto sarai l’unico
							ed il solo responsabile per la comunicazione di informazioni e di
							dati relativi a terzi soggetti, senza che questi lo abbiano
							espressamente richiesto, e per il loro uso non corretto o
							contrario alla legge. Resta inteso che Scarso Romualdo & Figli
							S.r.l. nei limiti di quanto prescritto dal Codice Privacy,
							adempirà all’obbligo di informativa nei confronti dell’utente
							segnalato e, ove necessario, richiederà il suo consenso espresso,
							all’atto della registrazione nei propri archivi dei relativi dati
							personali.
						</Typography>

						<Typography variant="h5" sx={{ mt: 8 }}>
							<Box fontWeight="Bold">
								3. OBBLIGATORIETÀ O FACOLTATIVITÀ DEL CONFERIMENTO DEI DATI
								PERSONALI – CONSEGUENZE
							</Box>
						</Typography>
						<Typography variant="body1" sx={{ mt: 4 }}>
							I dati individuati nel Sito con il carattere (*) (es. dati
							anagrafici, indirizzo di posta elettronica, indirizzo postale,
							carta di credito/debito) potrebbero essere indispensabili per
							adempiere ad obblighi derivanti da norme di legge o di regolamento
							ovvero per la fornitura di altri servizi resi sul Sito e da te
							richiesti. L’eventuale mancato conferimento di tali dati necessari
							potrebbe comportare l’impossibilità di di adempiere correttamente
							agli obblighi di legge e di regolamento. La mancata indicazione
							dei dati potrà dunque costituire, a seconda dei casi, un motivo
							legittimo e giustificato per non dare esecuzione al contratto di
							fornitura dei servizi tramite il Sito.
							<br />
							Il conferimento a Scarso Romualdo & Figli S.r.l. di ulteriori dati
							che non siano indicati con il simbolo (*) è invece facoltativa e
							non comporta alcuna conseguenza.
						</Typography>
						<Typography variant="h5" sx={{ mt: 8 }}>
							<Box fontWeight="Bold">
								4. A CHI COMUNICHIAMO I DATI PERSONALI
							</Box>
						</Typography>
						<Typography variant="body1" sx={{ mt: 4 }}>
							I dati personali potranno essere resi disponibili a terzi soggetti
							che svolgono in qualità di Responsabili del trattamento specifici
							servizi per conto di Scarso Romualdo & Figli S.r.l. o comunicati
							ad altri destinatari dei dati raccolti sul Sito, solo quando tale
							finalità non sia incompatibile con gli scopi per i quali i tuoi
							dati sono stati raccolti e successivamente trattati e, comunque,
							in modo conforme alla legge.
							<br />I dati non saranno comunicati, ceduti o, in altro modo,
							trasferiti ad altri soggetti terzi, senza che gli utenti non ne
							siano previamente informati e, con il loro consenso, quando ciò
							sia richiesto dalla legge.
						</Typography>
						<Typography variant="h5" sx={{ mt: 8 }}>
							<Box fontWeight="Bold">
								5. DIRITTO DI ACCESSO AI DATI ED ALTRI DIRITTI DELL’INTERESSATO
							</Box>
						</Typography>
						<Typography variant="body1" sx={{ mt: 4 }}>
							Nella tua qualità di interessato, ai sensi dell’art. 7 del Codice
							Privacy, hai il diritto di ottenere in qualunque momento la
							conferma dell’esistenza o meno dei Suoi dati e di conoscerne il
							contenuto e l’origine, verificarne l’esattezza o chiederne
							l’integrazione o l’aggiornamento, oppure la rettificazione.
							<br />
							Hai inoltre il diritto di chiedere la cancellazione, la
							trasformazione in forma anonima o il blocco dei dati trattati,
							nonché opporti in qualsiasi momento all’utilizzo dei Suoi dati per
							le finalità sopra descritte.
							<br />
							Potrai liberamente ed in qualsiasi momento esercitare i tuoi
							diritti, purché nei limiti di legge, con richiesta rivolta a
							Scarso Romualdo & Figli S.r.l. all’indirizzo di posta elettronica
							amministrazione@macelloscarso.it, alla quale provvederemo a dare
							opportuno riscontro. Per conoscere i tuoi diritti ed essere sempre
							aggiornato sulla normativa in materia di tutela delle persone
							rispetto al trattamento dei dati personali ti consigliamo di
							visitare il sito web del Garante per la protezione dei dati
							personali all’indirizzo http://www.garanteprivacy.it/
						</Typography>
						<Typography variant="h5" sx={{ mt: 8 }}>
							<Box fontWeight="Bold">
								6. MODIFICHE E AGGIORNAMENTI DELLA PRIVACY POLICY
							</Box>
						</Typography>
						<Typography variant="body1" sx={{ mt: 4 }}>
							Scarso Romualdo & Figli S.r.l. potrà modificare o semplicemente
							aggiornare, in tutto o in parte, la Privacy Policy del Sito anche
							in considerazione della modifica delle norme di legge o di
							regolamento che regolano questa materia e proteggono i tuoi
							diritti.
							<br />
							Le modifiche e gli aggiornamenti della Privacy Policy del Sito
							saranno notificati agli utenti nella Homepage del Sito non appena
							adottati e saranno vincolanti non appena pubblicati sul sito web
							in questa stessa sezione.
							<br />
							Ti preghiamo pertanto di accedere con regolarità a questa sezione
							per verificare la pubblicazione della più recente ed aggiornata
							Privacy Policy del Sito.
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
