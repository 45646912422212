import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Parallax from "../../common/Parallax/Parallax";

import scarso_img from "../../../../static/images/common/scarso_img.jpeg";
import polli_img from "../../../../static/images/common/Scarso-heroImage.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

const containerFluid = {
	paddingRight: "15px",
	paddingLeft: "15px",
	marginRight: "auto",
	marginLeft: "auto",
	width: "100%",
};
const container = {
	...containerFluid,
	"@media (min-width: 576px)": {
		maxWidth: "540px",
	},
	"@media (min-width: 768px)": {
		maxWidth: "720px",
	},
	"@media (min-width: 992px)": {
		maxWidth: "960px",
	},
	"@media (min-width: 1200px)": {
		maxWidth: "1140px",
	},
};

const parallaxContainer = {
	zIndex: "12",
	color: "#FFFFFF",
	...container,
};

const HomePage = () => {
	return (
		<Box>
			<Parallax filter image={polli_img}>
				<Box sx={parallaxContainer}>
					<Grid container>
						<Grid item xs={12} sm={12} md={12}>
							<Fade in timeout={3000}>
								<div>
									<Slide in direction="up" timeout={1500}>
										<Typography
											variant="h1"
											color="#fff"
											sx={{ mt: 4, minHeight: "32px" }}
										>
											Scarso Romualdo e Figli
										</Typography>
									</Slide>
								</div>
							</Fade>
						</Grid>
					</Grid>
				</Box>
			</Parallax>
			<Box
				sx={{
					padding: "0px 16px",
					p: 0,
					background: "#FFFFFF",
					position: "relative",
					zIndex: "3",
					margin: "-60px 30px 0px",
					borderRadius: "6px",
					boxShadow:
						"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
				}}
			>
				<Box
					sx={{
						py: 10,
						textAlign: "center",
						px: 30,
					}}
				>
					<Typography
						variant="h3"
						sx={{
							display: "inline-block",
							position: "relative",
							color: "#333",
						}}
					>
						Scarso Romualdo è un macello avicolo. Polli, capponi, galletti e
						galline grandi e piccole vengono macellati per conto di un numero
						limitato di committenti.
					</Typography>
				</Box>
				<Box
					sx={{
						background:
							"radial-gradient(ellipse at center,#585858 0,#232323 100%)",
						p: 0,
						textAlign: "center",
						marginAuto: 0,
						marginBottom: 0,
					}}
					// sx={{
					// 	display: "flex",
					// 	flexWrap: "wrap",
					// 	"& > :not(style)": {
					// 		// m: 1,
					// 		marginTop: 5,
					// 		maxWidth: "95%",
					// 		height: "auto",
					// 	},
					// 	justifyContent: "center",
					// 	border: 0,
					// 	// elevation: 0,
					// }}
				>
					<Grid container spacing={0}>
						<Grid item xs={6} sx={{ p: 10, color: "#f3f3f3" }}>
							<Typography gutterBottom variant="h5">
								Scarso Romualdo e Figli
							</Typography>
							<Typography variant="body1" sx={{ mb: 3 }}>
								E' un azienda storica, attiva nella macellazione di carni
								avicole, fornitura di uova, polli e galline, fondata dal Cav.
								Romualdo Scarso negli anni ’60 a Rio di Ponte San Nicolò (PD).
							</Typography>
							<Typography variant="body1" sx={{ mb: 3 }}>
								Romualdo Scarso ed arrivata oggi alla quarta generazione,
								fornisce tutta Italia con la propria azienda di trasporti
								refrigerati per garantire la catena del freddo.
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<CardMedia
								component="img"
								image={scarso_img}
								height="430"
								sx={{
									objectFit: "cover",
									objectPosition: "50% 0%",
									width: "100%",
									minHeight: "500px",
									clipPath: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
								}}
								alt="Azienda Scarso Romualdo"
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export default HomePage;
