// import { ThemeOptions } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "./fontStyle.css";
import { itIT } from "@mui/material/locale";
import { teal, orange } from "@mui/material/colors";

const baseUrl = process.env.APP_NAME;
const isTestEnv: boolean = process.env.APP_NAME == "tema_tst";

// export const themeOptions: ThemeOptions = {
export const theme = responsiveFontSizes(
	createTheme(
		{
			palette: {
				// type: "light",
				primary: {
					// main: "#9c27b0",
					main: isTestEnv ? "#9c27b0" : orange["A400"],
				},
				secondary: {
					main: isTestEnv ? "#00bcd4" : "#1b75b1",
				},
				text: {
					// primary: "#5d4037",
					// secondary: "rgba(141,110,99,0.8)",
					// forse bisogna inserire un module augmentation per dare un valore a hint che altrimenti non viene accettato
					// hint: "#a1887f",
					primary: "#191919",
					secondary: "rgba(25,25,25,0.8)",
				},
				error: {
					main: "#f44336",
				},
				info: {
					main: "#2196f3",
				},
				success: {
					main: "#69f0ae",
				},
				// divider: "rgba(121,85,72,0.2)",
				divider: "rgba(25,25,25,0.5)",
				background: {
					default: isTestEnv ? "#fa9898" : "#e0e0e0",
				},
			},
			typography: {
				fontFamily: "Ubuntu",
				h2: {
					fontFamily: "Oswald",
				},
				h3: {
					fontFamily: "Oswald",
				},
				h1: {
					fontFamily: "Oswald",
				},
				h5: {
					fontFamily: "Oswald",
				},
				h6: {
					fontFamily: "Oswald",
					fontSize: "1.2rem",
				},
				body1: {
					fontWeight: 100,
				},
				body2: {
					fontWeight: 100,
				},
				fontWeightLight: 200,
				fontWeightRegular: 300,
				subtitle1: {
					fontFamily: "Ubuntu",
				},
				subtitle2: {
					fontFamily: "Ubuntu",
					fontSize: "1.1rem",
					fontWeight: 300,
				},
				h4: {
					fontFamily: "Oswald",
				},
				overline: {
					fontFamily: "Oswald",
				},
			},
			spacing: 8,
			shape: {
				borderRadius: 4,
			},
			components: {
				MuiButton: {
					styleOverrides: {
						root: {
							// background: "linear-gradient(45deg, #90a4ae 30%, #607d8b 90%)",
							// border: 0,
							// borderRadius: 3,
							// boxShadow: "0 3px 5px 2px rgba(156, 39, 176, .3)",
							// color: "white",
							// height: 30,
							// padding: "0 30px",
							// marginLeft: 1,
							// marginRight: 1,
						},
					},
				},

				MuiAppBar: {
					styleOverrides: {
						root: {
							background: "linear-gradient(45deg, #00b8d4 30%, #00838f 90%)",
						},
					},
					defaultProps: {
						position: "sticky",
					},
				},
			},
		},
		itIT
	)
);
